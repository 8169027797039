import { type ClassValue, clsx } from 'clsx'
import { createTwc } from 'react-twc'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        // https://open-props.style/#typography
        'text-00',
        'text-0',
        'text-1',
        'text-2',
        'text-3',
        'text-4',
        'text-5',
        'text-6',
        'text-7',
        'text-8',
      ],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// https://react-twc.vercel.app/docs/guides/class-name-prop
// https://github.com/gregberge/twc/blob/main/src/index.tsx
export const twc = createTwc({ compose: cn })

// others:
// - https://github.com/nextui-org/tailwind-variants
